import React from 'react';

import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";

const Footer = () => (
  <footer className="footer has-text-white-ter">
    <div className="content has-text-centered">
      <div className="container">
        <div className="columns">
          <div className="column social" style={{ paddingBottom: '3rem' }}>
            <a
              title="facebook"
              href="https://www.facebook.com/travelsyogablog/"
            >
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            {/* <a title="twitter" href="#">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
            <a
              title="instagram"
              href="https://www.instagram.com/travelsyogablog/"
            >
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            {/* <a title="vimeo" href="#">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
